import { Fragment } from "react"
import { Check, X } from "react-feather"
import Avatar from "../@core/components/avatar"
const MEDIA_TYPES = [{ ext: "mov", type: "video" }, { ext: "mp4", type: "video" }, { ext: "jpg", type: "img" }, { ext: "jpeg", type: "img" }, { ext: "jfif", type: "img" }, { ext: "png", type: "img" }]
import themeConfig from "../configs/themeConfig";
import cartographyConfig from '../configs/cartographyConfig'
import moment from 'moment'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { Spinner } from "reactstrap";
import defaultAvatar from "@src/assets/images/portrait/small/avatar-s-11.jpg";
import { toast } from "react-toastify";
import axios from "axios";
const MySwal = withReactContent(Swal)

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

export const isMobileDevice = () =>  (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);

export const isArrEmpty = arr => arr.length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const customFormatDate = (value, se = "start") => {
  const periods = { "start": ' 00:00:00', 'end': " 23:59:59" };
  return moment(value).format(themeConfig.formats.date) + periods[se];
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: '2-digit', day: '2-digit', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const mySQLDateFormat = (value, se) => {
  const periods = { "start": ' 00:00:00', 'end': " 23:59:59" };
  if (!value) return value
  if (Array.isArray(value)) {
    let dateRange = []
    dateRange[0] = moment(value[0]).format("YYYY-MM-DD") + periods["start"];
    dateRange[1] = moment(value[1]).format("YYYY-MM-DD") + periods["end"];
    return dateRange;
  } else {
    return moment(value).format("YYYY-MM-DD") + periods[se]
  }
}
// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'member') return '/'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#de2929', // for selected option bg-color
    neutral10: '#de2929', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const ToastContainer = ({ title, message, type = 'success' }) => (
  <Fragment>
    <div className='toastify-header'>
      <div className='title-wrapper'>
        <Avatar size='sm' color={type} icon={type === 'success' ? <Check size={12} /> : <X size={12} />} />
        <h6 className='toast-title'>{title}!</h6>
      </div>
      {/* <small className='text-muted'>11 Min Ago</small> */}
    </div>
    <div className='toastify-body'>
      <span role='img' aria-label='toast-text'>
        {message}
      </span>
    </div>
  </Fragment>
)

export const toastMessage = (type="success",title, message) => {
  return toast[type](<ToastContainer type={type} message={message} title={title}  />, { autoClose: true, hideProgressBar: true, position: "top-center" })
}

export const ErrorToast = ({ title, message }) => (
  <Fragment>
    <div className='toastify-header'>
      <div className='title-wrapper'>
        <Avatar size='sm' color='success' icon={<Close size={12} />} />
        <h6 className='toast-title'>{title}!</h6>
      </div>
      {/* <small className='text-muted'>11 Min Ago</small> */}
    </div>
    <div className='toastify-body'>
      <span role='img' aria-label='toast-text'>
        {message}
      </span>
    </div>
  </Fragment>
)


export const UcFirst = (str) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

export const randomColor = () => {
  const stateNum = Math.floor(Math.random() * 5),
    states = ['light-success', 'light-warning', 'light-info', 'light-primary', 'light-secondary'],
    color = states[stateNum]
  return color
}

export const fileType = uri => {
  try {
    let ext = uri.split('.').pop().toString().toLowerCase();
    let _type =MEDIA_TYPES.find(t => {return ext.includes(t.ext)})
    _type= _type ? _type : {type:"unsupported"};
    return _type.type;
  } catch (error) {
    console.log(error.message);
  }
}

const convertArea = (area, unit) => {
  return cartographyConfig.CONVERSION_VALUES[unit] * area || area
}

export const unitConversion = (area, unit, shape, options = { withTranslate: false, unitName: 'short', unitOnly:false }) => {
  let { withTranslate, unitName, unitOnly } = options
  try {
    unitName = unitName === 'full' ? 'label' : 'nickName';
    area = convertArea(parseFloat(area), unit.toUpperCase())
    area = Math.round(parseFloat(area) * 10) / 10
  } catch (error) {
    // console.log("error")  
  } finally {
    let areaUnit = themeConfig.meaurementVars.find(el => el.value === unit)?.[unitName]
    areaUnit = typeof areaUnit == "undefined" ? "" : areaUnit;
    return unitOnly ? areaUnit : withTranslate ? `${area} ${areaUnit}` : area
  }
}

export const conversionMetric = (val, unit) => {
  if(cartographyConfig.CUSTOM_MEAS_UNITS.filter(el=>el.value===unit)?.length>0) return val
  let oneUnit = 1 / cartographyConfig.CONVERSION_VALUES[unit.toUpperCase()] || 1
  return val * oneUnit || val
}

export const showSimpleAlert = (title, message, html=false, confirm = true) => {
  let args = {
    title: title,
    text: message,
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    showClass: {
      popup: 'animate__animated animate__fadeIn'
    },
    buttonsStyling: false,
    showConfirmButton: confirm
  }
  if(html === true) {
    delete args['text'];
    args['html'] = message;
  }
  MySwal.fire(args)
}

export const shortLatLng = coordinates => coordinates.map(c => {return {lat: c.latitude, lng: c.longitude}})

export const longLatLng = coordinates => coordinates.map(c => {return {latitude: c.lat, longitude: c.lng}})

export const numerSuffix = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

export const handleConfirmCancel = ({ title, text, buttonText, cancelButtonText = 'Cancel', icon = true}) => {
  let args = {
    title: title,
    text: text,
    showCancelButton: true,
    confirmButtonText: buttonText,
    cancelButtonText: cancelButtonText,
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger ml-1'
    },
    buttonsStyling: false
  }
  if(icon === true) {
    args['icon'] = 'warning'
  }
  return MySwal.fire(args).then(function (result) {
    return result
  })
}

export const toLetters = num => {
  let mod = num % 26;
  let pow = num / 26 | 0;
  let out = mod ? String.fromCharCode(64 + mod) : (pow--, 'Z');
  return pow ? toLetters(pow) + out : out;
}

export const LoadingButton = () => (
  <><Spinner color='white' size='sm' />
      <span className='ml-50'>Loading...</span></>
)

export const deepCopy = arr => {
  let newArr = []
  try {
    newArr = JSON.parse(JSON.stringify(arr))
  } catch (err) {
    console.log(err)
  }
  return newArr
}

export const setPolygonBounds = ({zone_coordinates, coordinate_holes}) => {
    let paths = [deepCopy(zone_coordinates)]
    let holes= deepCopy(coordinate_holes)
    holes?.map(hole => {
      paths.push(hole?.reverse?.())
    })
    return paths
}

/* export const setPolygonBounds = ({zone_coordinates, coordinate_holes}) => {
  let paths = [deepCopy(zone_coordinates)]
  let holes= deepCopy(coordinate_holes)
  console.log('holes', holes);
  holes?.map(hole => {
    // console.log('hole', hole?.[0]?.lat > hole?.[1]?.lat);
    paths.push((hole?.[0]?.lat > hole?.[1]?.lat || hole?.[0]?.lng > hole?.[1]?.lng) ? hole : hole?.reverse?.())
  })
  return paths
} */


export const arrayChunks = (arr, numOfChunk) => {
  return arr.reduce((c, v, i, a) => {
      if (i + numOfChunk <= a.length) c.push(a.slice(i, i + numOfChunk));
      return c;
  }, [])
}

export const removeTransparency = color => {
  try{
    let newColor = color.search("#") == 0 ? hexToRgbShort(color, 1) : color
    let _c = newColor.replace(/[a-z()]/g, ''); _c = _c.split(",")
    return `rgba(${_c[0]},${_c[1]},${_c[2]},1)`
  }catch (error) {
    return color
  }
}

export const getMediaURI = uri => {
  return uri?.indexOf('firebasestorage') > -1 ? uri : `${themeConfig.app.cdnUrl}/${uri}`
}

export const getQueryString = (key) => {
  return new URLSearchParams(window.location.search).get(key);
}

export const getFilteredQueryString = (excludeParams = []) => {
  // Get the query string from the current URL
  const queryString = window.location.search;

  // Create a URLSearchParams object to work with query parameters
  const searchParams = new URLSearchParams(queryString);

  // Exclude specified parameters
  excludeParams.forEach(param => {
    searchParams.delete(param);
  });

  // Return the updated query string
  return searchParams.toString();
}

export const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
}

export const priceFormat = (price) => {
  price = parseFloat(price)
  return price < 0 ? `-$${Math.abs(price).toFixed(2)}` : `$${price.toFixed(2)}`;
}

export const invoiceAlert = (title, content) => {
  MySwal.fire({
    title: title,
    icon: 'success',
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false,
    html: content
  })
}

export const userAvatar = (avatar) => {
  return avatar ? themeConfig.app.storageUrl + "/images/" + avatar : defaultAvatar
}

export const parseMessage = (msg, allMembers, html = false) => {
  const placeholderRegex = /{{(.*?)}}/g;
  const userMentionRegex = /%%(.*?)%%/g;

  let replacedString = msg?.replace?.(placeholderRegex, (match, placeholder) => {
    const user = allMembers.find((el) => el.id == placeholder);
    if (user) {
      return html ? `<strong class="mention-u" contentEditable="false">@${user.name}</strong>` : `@${user.name}`;
    } else {
      return `@Unknown User`;
    }
  });

  replacedString = replacedString?.replace?.(userMentionRegex, (match, placeholder) => {
    return html ? `<strong class="mention-u" contentEditable="false">#${placeholder}</strong>` : `#${placeholder}`;
  });

  return replacedString;
};

export const formatMessage = (msg, array) => {
  const regex = new RegExp(`(${array.map(item => `\\${'@'+item.name}`).join('|')})`, 'g');
  
  return msg.replace(regex, (match, placeholder) => {
      const user = array.find((el) => el.name == placeholder?.replace('@', ''));
      if (user) {
        return `{{${user.id}}}`;
      }
      return '';
    });
}
export const getRandomString = (length) => {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  const charsetLength = charset.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charsetLength);
    result += charset.charAt(randomIndex);
  }

  return result;
}

export const fetchArea = ({ zone_area, area_unit, shape }) => {
  return unitConversion(zone_area, area_unit, shape, { withTranslate: true, unitName: 'full' })
}

export const getCostTotal = (cost_summaries) => {
  let cost = 0;
  cost_summaries && cost_summaries?.length>0 && cost_summaries.map((item)=>{
     cost = parseFloat(item?.cost) + cost
  })
  return cost.toFixed(2)
}



export const rgba2hex = colorCode => {
  try {
    var a,
      isPercent,
      rgb = colorCode
        .replace(/\s/g, '')
        .match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
      alpha = ((rgb && rgb[4]) || '').trim(),
      hex = rgb
        ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
        (rgb[2] | (1 << 8)).toString(16).slice(1) +
        (rgb[3] | (1 << 8)).toString(16).slice(1)
        : colorCode;
    a = alpha !== '' ? alpha : 1;
    // multiply before convert to HEX
    a = ((a * 255) | (1 << 8)).toString(16).slice(1);
    return colorCode.includes('#') ? hex : '#' + hex;
  } catch (error) {
    return colorCode;
  }
};

export const hexToRgbA = (hex, opacity) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      opacity +
      ')'
    );
  }
  return hex
  throw new Error('Bad Hex' + hex);
};

export const getColorByBgColor = (bgColor) => {
  if (!bgColor) { return ''; }
  return (parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2) ? '#000' : '#fff';
}

export const downloadFile = async (url, item) => {
  let response = await axios({ url: url, method: 'GET', responseType: 'blob', })
  const blob = new Blob([response.data]);
  const blobUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = item.file_name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(blobUrl);
}

export const getLocale = () => {
  return localStorage.getItem('locale') || 'en'
}

export const setLocale = (lang) => {
  localStorage.setItem('locale', lang)
}